.flowchart-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
    padding: 5vmax;
}

.centered-image {
    max-width: 100%;
    max-height: 100%;
}
  