.navbar {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: #fff;
  color: #00689b;
  padding: 2vmin 0;
  width: 100%;
  z-index: 1000;
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.1);
}

.navbar a {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #00689b;
  padding: 0.8rem 0.8rem;
}

.tab:hover {
  color: #fff;
  background-color: #00679b99;
}

.logo {
  width: 20vmin;
  height: 6vmin;
}

.navbar .tab {
  display: flex;
  align-items: center;
}

.navbar .icon {
  margin-right: 5px;
}

.tab.active {
  color: #fff;
  background-color: #00689b;
}

.tab {
  position: relative;
}

.dropdown-menu {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 100%;
  width: 20rem;
  left: 0;
  z-index: 1000;
  animation: popUpAnimation 0.2s ease 0.2s forwards;
  overflow: hidden;
}

@keyframes popUpAnimation {
  0% {
    opacity: 0;
    height: 0%;
  }
  100% {
    height: 200%;
    opacity: 1;
  }
}

.dropdown-tab {
  align-items: center;
  cursor: pointer;
}

.tab:hover .dropdown-menu {
  background-color: #fff;
  animation: popUpAnimation 0.2s ease 0s forwards;
}

.tab.mouseon {
  color: #fff;
  background-color: #00679b99;
}

.arrow {
  font-size: 1rem !important;
  margin-right: 0.5rem;
}

.tab:hover .dropdown-menu a {
  transition-delay: 1s;
}

.screen-overlay{
  position: fixed;
    height: 100vh;
    width: 100vw;
    left: 0;
    top: 0;
    background: transparent;
    visibility: hidden;
    opacity: 0;
    z-index: -1;
}

.menu-wrapper{
    position: fixed;
    height: 100vh;
    top: 0;
    bottom: 0;
    right: 0;
    background: #fff;
    z-index: 9999;
    transform: translateX(100%);
    overflow-x: hidden;
    transition: all 0.3s linear;
}

.menu-items{
  padding: 5vmin 5vmin;
}

.menu-items ul {
  list-style-type: none;
}

.mobile-nav ul {
  padding: 0;
}

.mobile-nav a{
  padding: 1vmin 0;
  width: 100%;
  font-size: 1.1rem;
}

.hamburger-menu{
  display: none;
}

.menu-icon {
  width: 2rem !important;
  height: 2rem !important;
}

@media screen and (max-width : 1024px) {
  .tab{
    display: none !important;
  }
  .menu-active{
    transform: translateX(0);
    transition: all 0.3s linear;
  }
  
  .active{
      background: rgba(0, 0, 0, 0.7);
      visibility: visible;
      opacity: 1;
      z-index: 9998;
  }
  .hamburger-menu{
    display: block;
  }

  .navbar{
    padding: 2vmin 3vmin;
    justify-content: space-between;
    box-sizing: border-box;
  }
  .logo{
    width: 10rem;
    height: 3rem;
  }
}
