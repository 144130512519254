.reason {
    color : #00689b;
    display: flex;
    flex-direction: column;
    align-items: center;

}
.why-finactro-container {
    display: flex;
    justify-content: space-between;
    margin-top: 12vmin;
    padding: 0 10%;
    align-items: start;
}

.why-finactro {
    margin : 2vmin;
}

.why-finactro-container img {
    max-width: 35vmax;
    max-height: 40vmax;
    height: 100%;
    object-fit: contain;
}

ul{
    padding: 0 1rem;
}

.why-finactro li{
    color: #00689b;
    line-height: 1.6rem;
    margin-bottom: 1rem;
}

.why-finactro-container h2 {
    font-weight: 600;
}

.why-finactro-container h3 {
    font-weight: normal;
}

.join {
    padding: 2% 9%;
    background: linear-gradient(to right, #00689b, #004d6e);
    color: white;
    margin-top: 5rem;
}

.join li {
    font-size: 3vmin;
    color: white;
}

.join a {
    font-size: 3vmin;
    color: white;
}

.join h3 {
    font-size: 3vmin;
    font-weight: normal;
}

@media screen and (max-width : 1400px) {
    .why-finactro-container {
        flex-direction: column;
        align-items: center;
        padding: 0 5%;
    }
    .reverse {
        flex-direction: column-reverse;
        margin-top: 2vmin;
    }
}