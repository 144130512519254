.footer {
    background-color: #00689b;
    padding: 30px;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  
  .footer-section {
    color: #fff;
    margin-bottom: 20px;
  }

  .footer-section a {
    color: #fff;
    text-decoration: none;
  }

  .footer-section a:hover {
    transition: 300ms;
    color: #6aaedb;
  }
  
  .footer-section h3 {
    font-weight: 500;
    margin-bottom: 10px;
  }
  
  .footer-section ul {
    list-style: none;
    padding: 0;
  }
  
  .footer-section li {
    margin-bottom: 5px;
  }

  .footer-section li a {
    color: #fff;
    text-decoration: none;
  }

  .footer-section li a:hover {
    transition: 300ms;
    color: #6aaedb;
  }

  .footer-section-container {
    display: flex;
    justify-content: space-around;
    width: 70%;
  }
  
  .social-media-links {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    width: 6rem;
  }
  
  .social-media-links img {
    width: 30px;
    margin-right: 10px;
    cursor: pointer;
  }
  
  .social-media-links img:last-child {
    margin-right: 0;
  }
  
  .social-media-links a {
    text-decoration: none;
  }

  .link-icon {
    color: #fff;
  }

  .link-icon:hover {
    transition: 300ms;
    color: #6aaedb;
  }

  .last-section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .copyright {
    text-align: center;
    font-size: 14px;
    color: #fff;
    margin-top: 20px;
  }

  @media screen and (max-width : 1400px) {
    .footer{
      display: block;
    }

    .last-section{
      align-items: center;
    }

    .footer-section-container{
      justify-content: space-around;
      width: 100%;
    }
  }

  @media screen and (max-width : 750px) {
    .footer-section-container{
      display: block;
    }
  }
  