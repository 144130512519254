.approach-section {
    display: flex;
  }
  
  .list-section {
    padding: 2rem;
    flex: 1;
    background-color: #00689b;
  }
  
  .list-section h2 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #fff;
  }
  
  .list-section ul {
    list-style: none;
    padding: 0;
  }
  
  .list-section li {
    font-size: 1.3rem;
    margin-bottom: 10px;
    color: #fff;
    margin-bottom: 1.2rem;
  }
  
  .list-section li strong {
    font-weight: bold;
  }
  
  .list-section button {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 16px;
    background-color: #fff;
    color: #00689b;
    border: solid;
    border-width: 0.09rem;
    border-color: #00689b;
    border-radius: 0.4rem;
    cursor: pointer;
  }
  
  .list-section button:hover {
    background-color: #00689b;
    color: #fff;
    transition: 300ms;
    border-color: #fff;
  }
  
  .image-section {
    flex: 1;
    text-align: center;
  }
  
  .image-section img {
    width: 100%;
    height: 100%;
  }

  @media screen and (max-width : 1024px) {
    .approach-section{
      flex-direction: column;
    }
    .list-section{
      padding: 1rem;
    }
  }
  