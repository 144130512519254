.location {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}
.location-element {
    padding: 20px;
    margin: 20px;
    text-align: center;
    width: fit-content;
  }
  
  .location-element img {
    max-width: 20rem;
    height: auto;
  }
  
  .location-element h2 {
    font-size: 2rem;
    color: #00689b;
    margin-top: 10px;
  }

  .address-section {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
  }
  .address-section p {
    margin: 0;
    margin-right: 10px;
    width: 40%;
  }
  
  .qr-code {
    display: flex;
    align-items: center;
  }
  
  
  /* Style for the container */
  .location-container {
    display: flex;
    justify-content: center;
    align-items: center;
    /* flex-wrap: wrap; */
  }